import { useState, FC } from 'react';

import { NumberFormField } from 'components/formFields';
import { useAssignCourseDispatcher } from 'providers/AssignCourseProvider/hooks/useAssignCourseDispatcher';
import { CourseAssignmentDuration } from 'providers/AssignCourseProvider/contexts/AssignCourseContext';

const OnboardingDays: FC<any> = ({
  courseId,
  daysToCompleteDefault,
  control,
}: {
  courseId: string;
  daysToCompleteDefault?: number;
  control: any;
}) => {
  const [daysToComplete, setDaysToComplete] = useState(daysToCompleteDefault);
  const { assignCourseState, setAssignCourseState } =
    useAssignCourseDispatcher();
  const { courseAssignmentsDuration, organizationAssignmentId } =
    assignCourseState;

  const setCourseAssignmentsDuration = (
    courseId: string,
    daysToComplete: number
  ) => {
    let updateCoursesAssignations: CourseAssignmentDuration[] = [];
    if (daysToComplete) {
      updateCoursesAssignations = addCoursesAssignation(
        courseId,
        daysToComplete
      );
    } else {
      updateCoursesAssignations = removeCoursesAssignation(courseId);
    }

    setAssignCourseState((prevState: any) => ({
      ...prevState,
      courseAssignmentsDuration: updateCoursesAssignations,
    }));
  };

  const addCoursesAssignation = (courseId: string, daysToComplete: number) => {
    let updateCoursesAssignations: CourseAssignmentDuration[] = [];

    const courseAssignmentDuration: CourseAssignmentDuration = {
      courseId,
      daysToComplete,
    };

    updateCoursesAssignations = courseAssignmentsDuration.filter(
      (courseAssignmentDuration: CourseAssignmentDuration) =>
        courseAssignmentDuration.courseId !== courseId
    );

    updateCoursesAssignations.push(courseAssignmentDuration);

    return updateCoursesAssignations;
  };

  const removeCoursesAssignation = (courseId: string) => {
    const updateCoursesAssignations = courseAssignmentsDuration.filter(
      (courseAssignmentDuration: CourseAssignmentDuration) =>
        courseAssignmentDuration.courseId !== courseId
    );

    if (organizationAssignmentId) {
      const courseIdsToDelete = courseAssignmentsDuration
        .filter(
          (courseAssignmentDuration: CourseAssignmentDuration) =>
            courseAssignmentDuration.courseId === courseId
        )
        .map(
          (courseAssignmentDuration: CourseAssignmentDuration) =>
            courseAssignmentDuration.courseId
        );

      setAssignCourseState((prevState: any) => ({
        ...prevState,
        courseIdsToDelete: [
          ...prevState.courseIdsToDelete,
          ...courseIdsToDelete,
        ],
      }));
    }

    return updateCoursesAssignations;
  };

  return (
    <NumberFormField
      onBlur={(event: any) => {
        const daysToComplete = event.target.value;
        setCourseAssignmentsDuration(courseId, daysToComplete);
      }}
      name="onboardingDays"
      control={control}
      label=""
      margin="dense"
      value={daysToComplete}
      min={1}
      onChange={(e: any) => {
        setDaysToComplete(e.target.value);
      }}
      disableWheel={true}
      triggerFieldOnchange={false}
    />
  );
};

export default OnboardingDays;
