import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import {
  formatNumber,
  formatPercentageSymbolWithPrecision,
} from 'utils/common';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {FilterState} from '../../../../components/filters/Filters';
import fileSaver from 'file-saver';
import Papa from 'papaparse';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

const loadingState = {
  courses: true,
  exporting: false,
};

const UserCourseReportGrid = ({ isAdmin }: { isAdmin: boolean }) => {
  const theme = useTheme();
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();
  const params = useParams();

  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchDownXS = useMediaQuery(theme.breakpoints.down('sm'));

  let controller = new AbortController();

  const getCourses = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    if (controller) controller.abort();
    try {
      controller = new AbortController();
      const { response } = await callService({
        resource:
          httpRoutes.reporting.userCourseAssignment.getUserCourseReport({
            params: { ...state },
            isAdmin: isAdmin || false,
            filters: filters,
            controller,
          }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const exportCourses = async () => {
    try {

      setLoading((prevState) => ({ ...prevState, exporting: true }));
      const { response } = await callService({
        resource:
          httpRoutes.reporting.userCourseAssignment.exportUserCourseReport({
            params: { ...state },
            isAdmin: isAdmin || false,
            filters: filters,
          }),
        successMessage: 'Export complete!',
      });

      if (response) {
        const formattedCsv = response.map((item: any) => {
          const {
            name,
            learnerCount,
            totalStarted,
            totalCompleted,
            averageScore,
            pastDueCount,
          } = item;

          const percentCompleted =
            learnerCount > 0 ? (totalCompleted / learnerCount) * 100 : 0;

          const percentCompletedLabel =
            formatPercentageSymbolWithPrecision(percentCompleted);

          return {
            'Course Name': name,
            Learners: learnerCount,
            'Total Started': totalStarted,
            'Total Completed': totalCompleted,
            'Past Due': pastDueCount,
            'Average Score': averageScore,
            'Percent Completed': percentCompletedLabel,
          };
        });

        const csv = Papa.unparse(formattedCsv);
        const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

        fileSaver.saveAs(
          csvData,
          'Users Assignment Report.csv'
        );

        setLoading((prevState) => ({ ...prevState, exporting: false }));
      }

    } catch (e) {
      console.log('Error');
    }
  };

  useEffect(() => {
    getCourses();
  }, [filters, state]);

  // set columns
  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Course Name',
      field: 'name',
      sortable: true,
      filterable: false,
      flex: 1,
    },
    {
      headerName: 'Learners',
      field: 'learnerCount',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.learnerCount);
      },
    },
    {
      headerName: 'Total Started',
      field: 'totalStarted',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.totalStarted);
      },
    },
    {
      headerName: 'Total Completed',
      field: 'totalCompleted',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.totalCompleted);
      },
    },
    {
      headerName: 'Past due',
      field: 'pastDueCount',
      sortable: true,
      filterable: false,
      headerAlign: 'center',
      type: 'number',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        return formatNumber(params.row.pastDueCount);
      },
    },
    {
      headerName: 'Average Score',
      field: 'averageScore',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        const { averageScore } = params.row;

        if (!params.row.averageScore) {
          return <>-</>;
        }

        const averageScoreLabel =
          formatPercentageSymbolWithPrecision(averageScore);

        return <>{averageScoreLabel}</>;
      },
    },
    {
      headerName: 'Percent Completed',
      field: 'test',
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'right',
      flex: 0.5,
      ColumnComponent: (params: any) => {
        const { totalCompleted, learnerCount } = params.row;

        const percentCompleted =
          learnerCount > 0 ? (totalCompleted / learnerCount) * 100 : 0;

        const percentCompletedLabel =
          formatPercentageSymbolWithPrecision(percentCompleted);

        return <>{percentCompletedLabel}</>;
      },
    },
  ];

  return (
    <PageContainer>
      <Box sx={{ px: matchDownLG ? 0 : 4, gap: 1 }}>
        <Box mb={2}>
          <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: matchDownXS ? '20px' : '40px',
                color: '#162738',
                lineHeight: '130%',
              }}
          >
            Users assignment report
          </Box>
        </Box>
        <DataGrid
          rows={gridData.rows}
          columns={columns}
          totalRows={gridData.totalRows}
          handleFilterChange={setFilters}
          handleExport={exportCourses}
          loading={loading.courses}
          filters={[]}
          onRowClick={(row) => {
            const mainRoute = isAdmin ? '/admin/reports/user-course-assignment/course' : '/reports/user-course-assignment/course';
            navigate(
              `${mainRoute}/${row.id}`
            );
          }}
        />
      </Box>
    </PageContainer>
  );
};

const UserCourseReport = ({ isAdmin }: { isAdmin: boolean }) => (
  <ControlledDataGrid>
    <UserCourseReportGrid isAdmin={isAdmin} />
  </ControlledDataGrid>
);

export default UserCourseReport;
