import { FolderSharedOutlinedIcon } from 'material-icons';
import { LinkProps } from 'layout/MainLayout/LeftNavigationDrawer/types';
import { isProdOrDev } from 'utils/environment';
import { link } from 'fs';

export const organizationManagementLinks: LinkProps[] = [
  {
    label: 'Organizations',
    link: '/admin/organizations',
    icon: 'organizations',
    isAdmin: true,
  },
  {
    label: 'Communities',
    link: '/admin/communities',
    icon: 'communities',
    isAdmin: true,
  },
  {
    label: 'User Management',
    link: '/admin/user-management',
    icon: 'user-management',
    isAdmin: true,
  },
];

export const courseManagementLinks: LinkProps[] = [
  {
    label: 'Course List',
    link: '/admin/course-list',
    icon: 'bullet-point',
    isAdmin: true,
  },
  {
    label: 'Bundles',
    link: '/admin/bundles',
    icon: 'bullet-point',
    isAdmin: true,
  },
  {
    label: 'Certificates',
    link: '/admin/certificates',
    icon: 'bullet-point',
    isAdmin: true,
  },
  {
    label: 'Rewards',
    link: '/admin/rewards',
    icon: 'bullet-point',
    isAdmin: true,
  },
];

export const adminReportingLinks: LinkProps[] = [
  {
    label: 'Courses Report',
    link: '/admin/reports/courses',
    icon: 'bullet-point',
  },
  {
    label: 'Learners Report',
    link: '/admin/reports/learners',
    icon: 'bullet-point',
  },
  {
    label: 'Communities Report',
    link: '/admin/reports/groups',
    icon: 'bullet-point',
  },
  {
    label: 'Membership Report',
    link: '/admin/reports/membership',
    icon: 'bullet-point',
  },
  {
    label: 'Communities Assignment Report',
    link: '/admin/reports/group-course-assignment',
    icon: 'bullet-point',
  },
  {
    label: 'Users Assignment Report',
    link: '/admin/reports/user-course-assignment',
    icon: 'bullet-point',
  },
];

export const reportingLinks = (
  permissions: string[],
  facilitatorGroupsIds: string[] = [],
  managerGroupIds: string[] = [],
) => {
  const links: LinkProps[] = [];

  // add learner report for all users
  links.push({
    label: 'My Learning Activity',
    link: '/reports/user',
    icon: 'bullet-point',
  });

  if (permissions.includes('reports:course:creator')) {
    links.push({
      label: 'Engagement Report',
      link: '/reports/engagement-report',
      icon: 'bullet-point',
    });
  }

  if (
    permissions.includes('reports:course:facilitator') &&
    (facilitatorGroupsIds.length > 0 || managerGroupIds.length > 0)
  ) {
    links.push({
      label: 'Facilitator Course Report',
      link: '/reports/courses',
      icon: 'bullet-point',
    });
  }

  if (
    permissions.includes('reports:learner') &&
    (facilitatorGroupsIds.length > 0 || managerGroupIds.length > 0)
  ) {
    const groupsIds = facilitatorGroupsIds.concat(managerGroupIds);
    const link =
        groupsIds.length === 1
        ? `/reports/learners/group/${groupsIds[0]}`
        : '/reports/learners';

    links.push({
      label: 'Learner Report',
      link,
      icon: 'bullet-point',
    });
  }

  if (
    permissions.includes('reports:community') &&
    (facilitatorGroupsIds.length > 0 || managerGroupIds.length > 0)
  ) {
    const groupsIds = facilitatorGroupsIds.concat(managerGroupIds);
    const link =
        groupsIds.length === 1
        ? `/reports/groups/${groupsIds[0]}`
        : '/reports/groups';
    links.push({
      label: 'Community Report',
      link,
      icon: 'bullet-point',
    });
  }

  if (
    permissions.includes('reports:course:facilitator') &&
    facilitatorGroupsIds.length > 0
  ) {
    links.push({
      label: 'Communities Assignment Report',
      link: '/reports/group-course-assignment',
      icon: 'bullet-point',
    },
    {
      label: 'Users Assignment Report',
      link: '/reports/user-course-assignment',
      icon: 'bullet-point',
    });
  }

  return links;
};

export const facilitatorDdlLinks = (permissions: string[]) => {
  const links: LinkProps[] = [];

  if (permissions.includes('communities:facilitator')) {
    links.push({
      label: 'Org Management',
      link: '/admin/ddl/org-management',
      icon: 'lock',
    });
  }

  links.push({
    label: 'Cronjobs',
    link: '/admin/cronjobs',
    icon: 'organizations',
    isAdmin: true,
  });

  return links;
};

export const adminPanelLinks = () => {
  const links: LinkProps[] = [];

  links.push({
    label: 'Npm Logs',
    link: '/admin/npmlogs',
    icon: 'folder',
    isAdmin: true,
  });

  if (isProdOrDev()) {
    links.push({
      label: 'Loops',
      link: '/admin/loops',
      icon: 'mail',
      isAdmin: true,
    });
  }

  return links;
};

export const userDdlLinks = (ddl: {
  basketSize: boolean;
  houseBrands: boolean;
  hasDutchieIntegration: boolean;
}) => {
  const links: LinkProps[] = [];

  if (ddl.basketSize) {
    links.push({
      label: 'Ticket Size',
      link: '/ddl/ticket-size',
      icon: 'basket',
    });
  }

  if (ddl.houseBrands) {
    links.push({
      label: 'House Brands',
      link: '/ddl/house-brands',
      icon: 'bullet-point',
    });
  }

  if (ddl.hasDutchieIntegration) {
    links.push({
      label: 'Scorecard',
      link: '/ddl/performance-report',
      icon: 'changesTracker',
    });
  }

  return links;
};

export const userLinks: LinkProps[] = [
  {
    label: 'Dashboard',
    link: '/',
    icon: 'dashboard',
  },
  {
    label: 'Course Library',
    link: '/courses',
    icon: 'course-library',
  },
];

export const facilitatorCommunitiesSubItemsLinks: LinkProps[] = [
  {
    label: 'Your Communities',
    link: '/communities',
    icon: 'bullet-point',
  },
  {
    label: 'Pending Members',
    link: '/communities/pending-members',
    icon: 'bullet-point',
    checkNotifications: true,
  },
];

export const facilitatorLinks: LinkProps[] = [
  {
    label: 'User Management',
    link: '/user-management',
    icon: 'user-management',
  },
];

export const organizationAdminLinks: LinkProps[] = [
  {
    label: 'Organization Management',
    link: '/organization-management',
    icon: 'organizations',
  }
];

export const profileLinks = [
  {
    icon: FolderSharedOutlinedIcon,
    label: 'Profile',
    link: '/profile',
  },
];

export const courseBuilderLinks = (
  hasCourseAssignmentPermissions: boolean,
  isCourseBuilder: boolean
) => {
  const links: LinkProps[] = [];

  if (isCourseBuilder) {
    links.push({
      icon: 'bullet-point',
      label: 'Course List',
      link: '/course-list',
    });

    links.push({
      icon: 'bullet-point',
      label: 'Bundles',
      link: '/bundles',
    });
  }

  if (hasCourseAssignmentPermissions) {
    links.push({
      icon: 'bullet-point',
      label: 'Course Assignment',
      link: '/course-assignment',
    });
  }

  return links;
};
