import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';

import Statistics from '../../../components/Statistics';
import MultiStoreCommunitiesGrid from '../../../components/MultiStoreCommunitiesGrid';
import Tag from '../../../components/Tag';

import { renderBoxColumn } from 'utils/tableRendering';
import { formatCurrency, formatPercentageSymbol } from 'utils/common';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import Typography from '@mui/material/Typography';

interface IMultiStore {
  kpiId: string;
  groupIds?: string[];
  onGroupClick: (groupId: string) => void;
}

// set columns
const columns = (navigateToGroup: any): DataGridColumnProps[] => {
  return [
    {
      headerName: 'Store',
      field: 'name',
      sortable: true,
      ColumnComponent: (params: any) => {
        return (
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '150%',
              color: '#508AC2',
              cursor: 'pointer',
            }}
            onClick={() => {
              navigateToGroup(params.row.id);
            }}
          >
            {params.row.name}
          </Box>
        );
      },
    },
    {
      headerName: 'Status',
      field: 'ddlOnboardingStatus',
      sortable: false,
      ColumnComponent: (params: any) => {
        return params.row.ddlOnboardingStatus ? (
          <Tag
            label="Enrolled"
            labelColor="#14804A"
            backgroundColor="#E1FCEF"
          />
        ) : (
          <Tag
            label="Not Enrolled"
            labelColor="#5A6376"
            backgroundColor="#E9EDF5"
          />
        );
      },
    },
    {
      headerName: 'Enrolled on',
      field: 'ddlActivatedOn',
      sortable: true,
      type: 'date',
      ColumnComponent: (params: any) => {
        if (params.row.ddlActivatedOn) {
          return (
            <>
              {new Date(params.row.ddlActivatedOn).toLocaleDateString('en-US')}
            </>
          );
        }
        return <>--</>;
      },
    },
    {
      headerName: 'Store Ticket Average',
      field: 'normalizedMean',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.normalizedMean, formatCurrency),
    },
    {
      headerName: 'Store Threshold',
      field: 'threshold',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.threshold, formatCurrency),
    },
    {
      headerName: '14 Day Growth',
      field: 'dollarsAdded14day',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.dollarsAdded14day, formatCurrency),
    },
    {
      headerName: '14 Day % Change',
      field: 'uplift14Day',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.uplift14Day, formatPercentageSymbol),
    },
    {
      headerName: '30 Day Growth',
      field: 'dollarsAdded30day',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.dollarsAdded30day, formatCurrency),
    },
    {
      headerName: '30 Day % Change',
      field: 'uplift30Day',
      sortable: true,
      ColumnComponent: (params: any) =>
        renderBoxColumn(params.row.uplift30Day, formatPercentageSymbol),
    },
  ];
};

const MultiStore = ({ kpiId, groupIds, onGroupClick }: IMultiStore) => {
  const theme: any = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  
  const {
    authState: { organization },
  } = useAuthState();

  return (
    <Box>
      <Box sx={{ my: 2 }}>
      <Box sx={{ mb: matchDownSM ? 2 : 0 }}>
        <Box>
          <Typography
            variant='h3'
            sx={{
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: matchDownSM ? '25px' : '32px',
              lineHeight: '130%',
              color: '#162738',
            }}
          >
            Basket Size Dashboard
          </Typography>
        </Box>
          <Box>
            <Typography
              variant='subtitle2'  
              fontWeight="bold"
              sx={{
                fontFamily: 'Raleway',
                fontSize: '16px',
                lineHeight: '24px',
                color: '#162738',
              }}
            >
              {`for ${organization.name}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Statistics
        type="multi"
        groupIds={groupIds}
        kpiId={kpiId}
        labelResult1="14 day growth"
        labelResultToolTip1="Sales Growth is calculated by taking in the increased average ticket size over the 14 days from the course completion date and comparing it to the previous 30 day average for each employee."
        labelResult2="14 day % change"
        labelResultToolTip2="Sales Growth percentage change is calculated by taking the average ticket size change over the 14 days from the course completion date and comparing it to the previous 30 day average for each employee."
        labelResult3="30 day growth"
        labelResultToolTip3="Sales Growth is calculated by taking in the increased average ticket size over the 30 days from the course completion date and comparing it to the previous 30 day average for each employee."
        labelResult4="30 day % change"
        labelResultToolTip4="Sales Growth percentage change is calculated by taking the average ticket size change over the 30 days from the course completion date and comparing it to the previous 30 day average for each employee."
      />
      <MultiStoreCommunitiesGrid
        columns={columns}
        kpiId={kpiId}
        onGroupClick={onGroupClick}
      />
    </Box>
  );
};

export default MultiStore;
