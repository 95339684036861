import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';

import { Alert, Box, Skeleton, useMediaQuery } from '@mui/material';
import DisplayText from 'components/DisplayText';
import CourseCompletions from '../facilitator/creator/partials/courseCompletions/CourseCompletions';
import TopStates from '../facilitator/creator/partials/topStates/TopStates';
import StatsBar from '../facilitator/creator/partials/statsBar/StatsBar';
import Icon from 'icons';
import TopCommunities from '../facilitator/creator/partials/topCommunities/TopCommunities';
import {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGrid } from 'components/dataGrid';
import { useTheme } from '@mui/material/styles';
import { formatPercentage, formatPercentageValue } from 'utils/common';
import { useSnackbar } from 'notistack';
import Overlay from 'features/courses/components/Overlay';
import {
  calculateDateRange,
  DateConfig,
  formatDateUSA,
  isAValidDateFilter,
} from '../../../utils/date';
import { REACT_APP_ENABLE_TIME_BASE_REPORT } from 'config/config';
import DateFilterMessages from './DateFilterMessages';
import {
  addDateFilterOptions,
  changeColumnTitles,
  getDates,
  setDateRangeFilters,
} from './utils';
import { isEmpty } from 'lodash';

let engagementReportGroupsRequestController: AbortController | undefined;

const loadingState = {
  course: true,
  groups: true,
  courseCompletions: true,
  states: true,
  stats: true,
  topCommunities: true,
  exporting: false,
};

const CreatorCourseReportDetailsGrid = () => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const searchParams = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  const [loading, setLoading] = useState(loadingState);
  const [courseName, setCourseName] = useState('');
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });
  const [selectedStartDateFilter, setSelectedStartDateFilter] = useState('');
  const [selectedEndDateFilter, setSelectedEndDateFilter] = useState('');

  const startDateQS = searchParams[0].get('startDate');
  const endDateQS = searchParams[0].get('endDate');

  const acceptedDateRangeFilter = calculateDateRange(
    '-3 months' as DateConfig,
    '-1 day' as DateConfig
  );

  const [filters, setFilters] = useState<FilterState>(new Map());

  const { id: courseId = '' } = params;

  const columns: DataGridColumnProps[] = [
    {
      headerName: 'Community Name',
      field: 'groupName',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Learners',
      field: 'totalLearners',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { totalLearners } = params.row;
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Icon name="user" size="small" />
            <Box>{totalLearners}</Box>
          </Box>
        );
      },
    },
    {
      headerName: 'Total Started',
      field: 'totalStarted',
      sortable: true,
    },
    {
      headerName: 'Total Completed',
      field: 'totalCompleted',
      sortable: true,
    },
    {
      headerName: 'Average Score',
      field: 'averageScore',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { averageScore } = params.row;
        return <Box>{averageScore ? `${Math.round(averageScore)}%` : '-'}</Box>;
      },
    },
    {
      headerName: '% Completed',
      field: 'percentCompleted',
      sortable: true,
      ColumnComponent: (params: any) => {
        const { percentageCompleted } = params.row;

        if (percentageCompleted === null) {
          return '-';
        }

        return (
          <>
            {percentageCompleted <= 1
              ? formatPercentage(parseFloat(percentageCompleted), 0)
              : formatPercentageValue(parseFloat(percentageCompleted))}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setDateRangeFilters(filters, startDateQS, endDateQS);
    getGroups();
    getCourse();
  }, []);

  useEffect(() => {
    setLoading((prevState: any) => ({
      ...prevState,
      groups: true,
    }));
  }, [state]);

  useEffect(() => {
    getGroups();
  }, [courseId, loading.groups === true]);

  useEffect(() => {
    getCourse();
  }, [courseId]);

  const search = () => {
    setLoading((prevState: any) => ({
      ...prevState,
      groups: true,
      courseCompletions: true,
      states: true,
      stats: true,
      topCommunities: true,
    }));
  };

  const restarLoading = () => {
    setLoading((prevState: any) => ({
      ...prevState,
      groups: false,
      courseCompletions: false,
      states: false,
      stats: false,
      topCommunities: false,
    }));
  };

  const getGroups = async () => {
    const [selectedStartDate, selectedEndDate] = getDates(filters);

    if (!selectedStartDate && selectedEndDate) {
      restarLoading();
      return;
    }

    if (selectedStartDate && !selectedEndDate) {
      restarLoading();
      return;
    }

    if (selectedStartDate && selectedEndDate) {
      const validDateFilter = isAValidDateFilter(
        acceptedDateRangeFilter,
        selectedStartDate,
        selectedEndDate
      );

      if (!validDateFilter) {
        restarLoading();
        return;
      } else {
        setSelectedStartDateFilter(selectedStartDate);
        setSelectedEndDateFilter(selectedEndDate);
      }
    }

    if (
      selectedStartDate &&
      selectedEndDate &&
      selectedStartDate > selectedEndDate
    ) {
      restarLoading();
      return;
    }

    if (engagementReportGroupsRequestController)
      engagementReportGroupsRequestController.abort();

    try {
      if (courseId) {
        engagementReportGroupsRequestController = new AbortController();

        if (selectedStartDate && selectedEndDate) {
          console.log('CREATOR COURSE REPORT DATE FILTER USED');
        }

        const { response } = await callService({
          resource: httpRoutes.reporting.course.getCreatorCourseGroupReport({
            courseId,
            params: { ...state },
            filters: filters,
            controller: engagementReportGroupsRequestController,
          }),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
          changeColumnTitles(columns, selectedStartDate, selectedEndDate);
          engagementReportGroupsRequestController = undefined;

          setLoading((prevState) => ({ ...prevState, groups: false }));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  // set filter model
  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Community Name',
      props: {
        infoMsg:
          'Press ENTER or click "Apply Filters" to search by community name',
        onClearValue: search,
      },
    },
    {
      id: 'location',
      type: 'location',
      label: 'Location',
    },
  ];

  addDateFilterOptions(
    filterModel,
    '-3 months',
    '-1 day',
    startDateQS,
    endDateQS
  );

  const getCourse = async () => {
    try {
      if (courseId) {
        const { response } = await callService({
          resource: httpRoutes.courses.getCourseById(courseId),
        });

        if (response) {
          setCourseName(response.name);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prevState) => ({ ...prevState, course: false }));
    }
  };

  const exportGroups = async () => {
    try {
      setLoading((prevState) => ({ ...prevState, exporting: true }));
      if (courseId) {
        const [selectedStartDate, selectedEndDate] = getDates(filters);

        if (!selectedStartDate && selectedEndDate) return;
        if (selectedStartDate && !selectedEndDate) return;

        if (!selectedStartDate && selectedEndDate) {
          enqueueSnackbar(
            'Please select a start date to filter by date range.',
            {
              variant: 'info',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }
          );

          restarLoading();
          return;
        }

        if (selectedStartDate && !selectedEndDate) {
          enqueueSnackbar(
            'Please select an end date to filter by date range.',
            {
              variant: 'info',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }
          );

          restarLoading();
          return;
        }

        const validDateFilter = isAValidDateFilter(
          acceptedDateRangeFilter,
          selectedStartDate,
          selectedEndDate
        );
        if (selectedStartDate && selectedEndDate && !validDateFilter) {
          enqueueSnackbar(
            `The selected date must be between ${formatDateUSA(
              acceptedDateRangeFilter.calculatedMinDate
            )} and ${formatDateUSA(acceptedDateRangeFilter.calculatedMaxDate)}`,
            {
              variant: 'info',
              anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
              },
            }
          );

          restarLoading();
          return;
        }

        if (
          selectedStartDate &&
          selectedEndDate &&
          selectedStartDate > selectedEndDate
        ) {
          enqueueSnackbar('End date must be greater than start date.', {
            variant: 'info',
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top',
            },
          });

          restarLoading();
          return;
        }

        const { response } = await callService({
          resource: httpRoutes.reporting.course.exportCreatorCourseGroupReport({
            courseId,
            params: { ...state },
            filters: filters,
          }),
          successMessage: 'Export complete!',
        });

        if (response) {
          const prefix = selectedStartDate && selectedEndDate ? 'New' : 'Total';

          const formatCSVResults = (results: any[]) => {
            return results.map((result: any) => {
              return {
                'Community Name': result['Community Name'],
                [`${prefix} Learners`]: result[`${prefix} Learners`],
                [`${prefix} Started`]: result[`${prefix} Started`],
                [`${prefix} Completed`]: result[`${prefix} Completed`],
                'Average Score': result['Average Score'],
                'Percentage Completed': !isEmpty(result['Percentage Completed'])
                  ? `${result['Percentage Completed']}%`
                  : '0%',
              };
            });
          };
          const csv = Papa.unparse(formatCSVResults(response));
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(csvData, `${courseName} Creator Course Report.csv`);

          const formatResults = (results: any[]) => {
            return results.map((result: any) => {
              return {
                id: result.id,
                groupName: result['Community Name'],
                totalLearners: result[`${prefix} Learners`],
                totalStarted: result[`${prefix} Started`],
                totalCompleted: result[`${prefix} Completed`],
                averageScore: result['Average Score'],
                percentageCompleted: !isEmpty(result['Percentage Completed'])
                  ? result['Percentage Completed']
                  : 0,
              };
            });
          };

          setGridData({
            rows: formatResults(response),
            totalRows: response.length,
          });
          changeColumnTitles(columns, selectedStartDate, selectedEndDate);
        }
      }
    } catch (e) {
      console.log('Error exporting on engagement report details');
    } finally {
      setLoading((prevState: any) => ({
        ...prevState,
        courseCompletions: true,
        states: true,
        stats: true,
        topCommunities: true,
        exporting: false,
      }));
    }
  };

  const selectedLocation = filters.get('location');
  const selectedName = filters.get('name') ? filters.get('name') : '';
  const [selectedStartDate, selectedEndDate] = getDates(filters);

  return (
    <PageContainer>
      <Overlay
        open={
          loading.course ||
          loading.groups ||
          loading.courseCompletions ||
          loading.states ||
          loading.stats ||
          loading.topCommunities ||
          loading.exporting
        }
        zIndex={9999999}
      />
      <Box
        onKeyUpCapture={(e: any) => {
          if (e.keyCode === 13) {
            search();
            const [selectedStartDate, selectedEndDate] = getDates(filters);
            if (!selectedStartDate && !selectedEndDate) {
              setSelectedStartDateFilter('');
              setSelectedEndDateFilter('');
            }
          }
        }}
      >
        <Box sx={{ px: matchDownLG ? 0 : 4 }}>
          <Box sx={{ mb: 2 }}>
            {loading.course ? (
              <Skeleton variant="rectangular" height={70} width="40%" />
            ) : (
              <DisplayText
                type="h2"
                text={courseName}
                variant="semiBold"
                style={{ color: '#162738' }}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 5 }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {selectedStartDateFilter && selectedEndDateFilter ? (
                <Alert severity="info" variant="outlined">
                  Report calculated from{' '}
                  <strong>{formatDateUSA(selectedStartDateFilter)}</strong> to{' '}
                  <strong>{formatDateUSA(selectedEndDateFilter)}</strong>
                </Alert>
              ) : (
                <></>
              )}
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              <TopCommunities
                courseId={courseId}
                startDate={selectedStartDate}
                endDate={selectedEndDate}
                location={selectedLocation}
                name={selectedName}
                setLoading={setLoading}
                loading={loading}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0.37 0 auto',
                  width: matchDownLG ? '100%' : '345px',
                  gap: 1,
                }}
              >
                <CourseCompletions
                  courseId={courseId}
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  location={selectedLocation}
                  name={selectedName}
                  setLoading={setLoading}
                  loading={loading}
                />
                <TopStates
                  startDate={selectedStartDate}
                  endDate={selectedEndDate}
                  courseId={courseId}
                  location={selectedLocation}
                  name={selectedName}
                  setLoading={setLoading}
                  loading={loading}
                />
              </Box>
            </Box>
            <StatsBar
              courseId={courseId}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              state={selectedLocation}
              name={selectedName}
              setLoading={setLoading}
              loading={loading}
            />
          </Box>

          {REACT_APP_ENABLE_TIME_BASE_REPORT && (
            <DateFilterMessages
              acceptedDateRangeFilter={acceptedDateRangeFilter}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
            />
          )}

          <DataGrid
            sync={REACT_APP_ENABLE_TIME_BASE_REPORT}
            rows={gridData.rows}
            totalRows={gridData.totalRows}
            columns={columns}
            handleFilterChange={setFilters}
            loading={loading.groups}
            filters={filterModel}
            handleExport={exportGroups}
            filtersButtonCallback={search}
            onRowClick={(params) => {
              let url = `/reports/engagement-report/${courseId}/group/${params.id}`;

              if (selectedStartDate) {
                url += `?startDate=${
                  selectedStartDate.toISOString().split('T')[0]
                }`;
                if (selectedEndDate) {
                  url += `&endDate=${
                    selectedEndDate.toISOString().split('T')[0]
                  }`;
                }
              }
              navigate(url);
            }}
          />
        </Box>
      </Box>
    </PageContainer>
  );
};

const CreatorCourseReportDetails = () => (
  <ControlledDataGrid>
    <CreatorCourseReportDetailsGrid />
  </ControlledDataGrid>
);

export default CreatorCourseReportDetails;
