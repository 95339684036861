import { FC, useEffect, useState } from 'react';

import { Alert, Divider, Grid, Typography } from '@mui/material';

import { ControlledDataGrid } from 'components/dataGrid/components/DataGrid';
import UsersTable from './UsersTable';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

const FormSectionTitle = ({ title }: { title: string }) => {
  return (
    <Grid item xs={12} mt={3}>
      <Typography variant="h3">{title}</Typography>
      <Divider sx={{ mb: 2, mt: 1 }} />
    </Grid>
  );
};

const UsersListGrid: FC<any> = ({
  organizationId,
  isOrganizationManager,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [usersHasBeenFound, setUsersHasBeenFound] = useState(false);
  const { callService } = useCallService();

  useEffect(() => {
    getOrganizationHasUsers();
  }, []);

  const getOrganizationHasUsers = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource:
          httpRoutes.assignCourse.getOrganizationHasUsers(organizationId),
      });

      if (response) {
        setUsersHasBeenFound(response.hasUsers);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Grid
      container
      flexDirection="row"
      sx={{ p: 2 }}
      justifyContent="space-between"
    >
      <Grid container columnSpacing={1}>
        <Grid item xs={12} mb={1}>
          <FormSectionTitle title="Select Your Users" />
        </Grid>
        <Grid item xs={12} mb={1}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Raleway',
              marginTop: '15px',
            }}
          >
            Select which users should be included in the assignment
          </Typography>
        </Grid>
        {!usersHasBeenFound ? (
          <Alert severity="info">The organization has no users.</Alert>
        ) : (
          <Grid item xs={12} sx={{ height: '400px', overflowY: 'scroll' }}>
            <UsersTable
              organizationId={organizationId}
              isOrganizationManager={isOrganizationManager}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const Users = ({
  organizationId,
  isOrganizationManager,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
}) => (
  <ControlledDataGrid>
    <UsersListGrid
      organizationId={organizationId}
      isOrganizationManager={isOrganizationManager}
    />
  </ControlledDataGrid>
);

export default Users;
