import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldError, useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { Button, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import fileSaver from 'file-saver';
import Papa from 'papaparse';

import {
  CheckboxFormField,
  FileFormField,
  MultiSelectFormField,
  SelectFormField,
} from 'components/formFields';

import { SelectOptionProps } from 'components/formFields/types';
import FormSection from 'components/form/FormSection';

import { useCallService } from 'hooks';

import { SaveIcon } from 'material-icons';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';

import fileManagement from 'utils/s3';
import httpRoutes from 'utils/httpRoutes';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import UserUploadFormInput from './UserUploadFormInput';

export const INITIAL_FORM_STATE: UserUploadFormInput = {
  organizationId: '',
  groupIds: [],
  primaryGroupId: '',
  roleIds: [],
  sendNotifications: false,
  ssoEnabled: false,
};

const UserUploadForm = ({
  isLoading,
  onSubmit,
}: {
  isLoading: boolean;
  onSubmit: (values: UserUploadFormInput) => void;
}) => {
  const [groups, setGroups] = useState<SelectOptionProps[]>([]);
  const [roles, setRoles] = useState<SelectOptionProps[]>([]);
  const [primaryGroupOptions, setPrimaryGroupOptions] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const { hideDialog } = useDialogDispatcher();
  const { callService } = useCallService();
  const {
    authState: { organization },
  } = useAuthState();

  const preSubmitUpload = async (values: UserUploadFormInput) => {
    if (!values.userFile) {
      enqueueSnackbar('Please select a file to upload before proceeding.', {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });
    } else {
      enqueueSnackbar('Uploading file. Please stay on page.', {
        variant: 'info',
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top',
        },
      });

      const { name } = values.userFile[0];

      // Remove all whitespace from filename
      const fileName = name.replace(/\s/g, '');

      const fileUrl = encodeURIComponent(fileName);
      const fileUrlRawName = fileName;

      const response = await fileManagement.putItemWithPresignedUrl({
        key: fileUrl,
        body: values.userFile[0],
      });

      if (response) {
        enqueueSnackbar('File uploaded successfully!', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });

        values.userFileUrl = fileUrlRawName;

        delete values.userFile;
      } else {
        enqueueSnackbar('File upload failed!', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'right',
            vertical: 'top',
          },
        });
      }
    }

    values.organizationId = organization.id;
    onSubmit(values);
  };

  const validationSchema = Yup.object().shape({
    groupIds: Yup.array().required('Please select at least one community'),
    primaryGroupId: Yup.string().required('Please select a primary community'),
    roleIds: Yup.array().required('Please select at least one role'),
    userFile: Yup.mixed().nullable(),
    sendNotifications: Yup.boolean().default(false),
    ssoEnabled: Yup.boolean().default(false),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UserUploadFormInput>({
    mode: 'onBlur',
    defaultValues: INITIAL_FORM_STATE,
    resolver: yupResolver(validationSchema),
  });

  const getRoles = async () => {
    const { response } = await callService({
      resource: httpRoutes.userManagement.getRoles(),
    });

    if (response) {
      setRoles(
        response.map((item: { id: string; name: string }) => {
          return { value: item.id, label: item.name };
        })
      );
    }
  };

  const getGroups = async () => {
    const { response } = await callService({
      resource: httpRoutes.organizations.getGroups({
        organizationId: organization.id,
      }),
    });

    if (response) {
      setGroups(
        response.items.map((item: { id: string; name: string }) => {
          return { value: item.id, label: item.name };
        })
      );
    }
  };

  const setGroupsToSelectPrimary = (selectedGroup: any) => {
    const groupIdsToSelectPrimary = selectedGroup
      .map((val: any) => {
        const option = groups.find((g) => g.value === val);
        return option ? { value: option.value, label: option.label } : null;
      })
      .filter(Boolean);
    setPrimaryGroupOptions(groupIdsToSelectPrimary);
  };

  const downLoadTemplate = () => {
    const csv = Papa.unparse({
      fields: ['First Name', 'Last Name', 'Email'],
      data: [],
    });

    const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });
    fileSaver.saveAs(csvData, 'User import template.csv');
  };

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    getGroups();
  }, []);

  return (
    <Grid container>
      <form
        onSubmit={handleSubmit(preSubmitUpload)}
        style={{ width: 'inherit' }}
      >
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Upload Users
          </Typography>
          <FormSection title="Select the file to upload">
            <Grid container xs={12}>
              <Grid item xs={3}>
                <FileFormField
                  name="userFile"
                  control={control}
                  label="User File"
                />
              </Grid>
              <Grid item xs={9}>
                <Button
                  variant="contained"
                  onClick={downLoadTemplate}
                  sx={{ marginTop: '1rem' }}
                >
                  Download Template
                </Button>
              </Grid>
            </Grid>
          </FormSection>
          <FormSection title="Select the Communities">
            <Grid item xs={12}>
              <MultiSelectFormField
                name="groupIds"
                control={control}
                label="Communities"
                onChange={setGroupsToSelectPrimary}
                errors={errors.groupIds as FieldError[]}
                options={groups}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <SelectFormField
                name="primaryGroupId"
                control={control}
                label="Primary community"
                errors={errors.primaryGroupId}
                options={primaryGroupOptions}
                required
              />
            </Grid>
          </FormSection>
          <FormSection title="Select the Roles/Permissions these users will have">
            <Grid item xs={12}>
              <MultiSelectFormField
                name="roleIds"
                control={control}
                label="Roles"
                errors={errors.roleIds as FieldError[]}
                options={roles}
                required
              />
            </Grid>
          </FormSection>
          <FormSection title="Do you want to send invitation emails to the new users?">
            <Grid item xs={12}>
              <CheckboxFormField
                name="sendNotifications"
                control={control}
                label="Send Email Invitations"
              />
            </Grid>
          </FormSection>
          <FormSection title="SSO Settings">
            <Grid item xs={12}>
              <CheckboxFormField
                name="ssoEnabled"
                control={control}
                label="Enable SSO for uploaded users"
              />
            </Grid>
          </FormSection>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isSubmitting || isLoading}
            loading={isSubmitting || isLoading}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </form>
    </Grid>
  );
};

export default UserUploadForm;
