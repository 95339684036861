import { useContext } from 'react';
import AssignCourseContext from '../contexts/AssignCourseContext';

export const useAssignCourseDispatcher = () => {
  const { assignCourseState, setAssignCourseState, getOrganizationAssignment } =
    useContext(AssignCourseContext);

  return {
    assignCourseState,
    setAssignCourseState,
    getOrganizationAssignment,
  };
};
