import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { TextFormField } from 'components/formFields';
import { useCallService } from 'hooks';
import { SaveIcon } from 'material-icons';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useSnackbar } from 'notistack';

import httpRoutes from 'utils/httpRoutes';

export type OrganizationSSOConfigurationFormInput = {
  organizationId: string;
  entryPoint: string;
  issuer: string;
  cert: string | null;
};

const OrganizationSSOConfigurationForm = ({
  organizationId,
  onSuccess,
}: {
  organizationId: string;
  onSuccess: VoidFunction;
}) => {
  const { callService } = useCallService();
  const { hideDialog } = useDialogDispatcher();
  const { enqueueSnackbar } = useSnackbar();
  const [certificateFile, setCertificateFile] = useState<File | null>(null);

  const onSubmit = async (values: OrganizationSSOConfigurationFormInput) => {
    if (!certificateFile) {
      enqueueSnackbar('Please upload a certificate file', { variant: 'error' });
      return;
    }

    // Read the certificate file
    const reader = new FileReader();
    reader.onload = async (e) => {
      const certificateContent = e.target?.result as string;
      
      const { response } = await callService({
        resource: httpRoutes.organizations.addSingleSignOnConfiguration({
          ...values,
          cert: certificateContent,
        }),
        successMessage: 'SSO Configuration saved successfully!',
      });

      if (response) {
        onSuccess();
        hideDialog();
      }
    };

    reader.readAsText(certificateFile);
  };

  const validationSchema = Yup.object().shape({
    entryPoint: Yup.string()
      .url('Must be a valid URL')
      .required('Entry point URL is required'),
    issuer: Yup.string().required('Issuer is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<OrganizationSSOConfigurationFormInput>({
    mode: 'onBlur',
    defaultValues: {
      organizationId,
      entryPoint: '',
      issuer: '',
      cert: null,
    },
    shouldUnregister: false,
    resolver: yupResolver(validationSchema),
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type !== 'application/x-x509-ca-cert') {
      enqueueSnackbar('Please upload a valid certificate file (.crt)', {
        variant: 'error',
      });
      return;
    }
    setCertificateFile(file || null);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container flexDirection="column" sx={{ p: 2 }}>
          <Typography variant="h3" sx={{ pb: 2 }}>
            Configure SSO
          </Typography>
          <Grid
            container
            flexDirection="row"
            justifyContent="space-between"
            columnSpacing={1}
          >
            <Grid item xs={12}>
              <TextFormField
                name="entryPoint"
                control={control}
                label="Identity Provider SSO URL*"
                errors={errors.entryPoint}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFormField
                name="issuer"
                control={control}
                label="Identity Provider Issuer*"
                errors={errors.issuer}
                margin="dense"
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept=".crt,.cer"
                style={{ display: 'none' }}
                id="certificate-file"
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="certificate-file">
                <Button
                  variant="outlined"
                  component="span"
                  fullWidth
                  sx={{ mt: 1, mb: 1 }}
                >
                  {certificateFile ? certificateFile.name : 'Upload Certificate (.crt)'}
                </Button>
              </label>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" columnSpacing={1}>
          <Button variant="text" onClick={hideDialog}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            disabled={isSubmitting || !certificateFile}
            loading={isSubmitting}
            loadingPosition="start"
            startIcon={<SaveIcon />}
          >
            Save
          </LoadingButton>
        </Grid>
      </form>
    </div>
  );
};

export default OrganizationSSOConfigurationForm;
