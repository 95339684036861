import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Skeleton, useMediaQuery } from '@mui/material';
import Papa from 'papaparse';
import fileSaver from 'file-saver';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import PageContainer from 'components/PageContainer';

import { FilterType } from 'components/filters/filterTypes';
import { FilterState } from 'components/filters/Filters';

import { useCallService } from 'hooks';

import httpRoutes from 'utils/httpRoutes';
import { formatPercentageSymbol } from 'utils/common';

import TopLearners from './partials/topLearners/TopLeaners';
import TopCourses from './partials/topCourses/TopCourses';
import TotalCourses from './partials/totalCourses/TotalCourses';
import Stats from './partials/stats/Stats';

// set columns
const columns: DataGridColumnProps[] = [
  {
    headerName: 'Course Name',
    field: 'courseName',
    sortable: true,
    filterable: false,
    flex: 1,
  },
  {
    headerName: 'Total Started',
    field: 'startedCount',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
  },
  {
    headerName: 'Total Completed',
    field: 'completedCount',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    type: 'number',
    flex: 0.5,
  },
  {
    headerName: 'Average Score',
    field: 'averageScore',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      const { averageScore } = params.row;

      if (!averageScore) return '-';

      return formatPercentageSymbol(averageScore);
    },
  },
  {
    headerName: 'Percent Completed',
    field: 'percentageCompleted',
    sortable: true,
    filterable: false,
    headerAlign: 'center',
    align: 'right',
    flex: 0.5,
    ColumnComponent: (params: any) => {
      const { percentageCompleted } = params.row;

      if (!percentageCompleted) return '-';

      return formatPercentageSymbol(percentageCompleted);
    },
  },
];

// set filter model
const filterModel: FilterType[] = [
  {
    id: 'name',
    type: 'text',
    label: 'Course Name',
  },
];

const loadingState = {
  courses: true,
  exporting: false,
};

const GroupCourseReportGrid = () => {
  const theme = useTheme();
  const { state } = useDataGridState();
  const { callService } = useCallService();

  const [title, setTitle] = useState({ location: '', groupName: '' });
  const [titleLoading, setTitleLoading] = useState(true);
  const [loading, setLoading] = useState(loadingState);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const navigate = useNavigate();
  const params = useParams();

  const matchDownLg = useMediaQuery(theme.breakpoints.down('xl'));
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchDownXS = useMediaQuery(theme.breakpoints.down('sm'));

  const getGroup = async () => {
    try {
      if (params.id) {
        const { response } = await callService({
          resource: httpRoutes.groups.getOne(params.id),
        });

        if (response) {
          let location = '';
          if (response.city && response.state) {
            location = `${response.city}, ${response.state.name}`;
          }
          setTitle({ location, groupName: response.name });
        }
      }
    } finally {
      setTitleLoading(false);
    }
  };

  const getGroupCourses = async () => {
    setLoading((prevState) => ({ ...prevState, courses: true }));
    try {
      if (params.id) {
        const { response } = await callService({
          resource: httpRoutes.reporting.group.getGroupCourseReport({
            groupId: params.id,
            params: { ...state },
            filters: filters,
          }),
        });

        if (response) {
          setGridData({
            rows: response.items,
            totalRows: response.totalRows,
          });
        }
      }
    } finally {
      setLoading((prevState) => ({ ...prevState, courses: false }));
    }
  };

  const exportGroups = async () => {
    try {
      if (params.id) {
        setLoading((prevState) => ({ ...prevState, exporting: true }));
        const { response } = await callService({
          resource: httpRoutes.reporting.group.exportGroupCourseReport({
            groupId: params.id,
            params: { ...state },
            filters: filters,
          }),
          successMessage: 'Export complete!',
        });

        if (response) {
          const csv = Papa.unparse(response);
          const csvData = new Blob([csv], { type: 'text/plain:charset=utf-8' });

          fileSaver.saveAs(csvData, 'Community Course Report.csv');
        }
      }
    } finally {
      setLoading((prevState) => ({ ...prevState, exporting: false }));
    }
  };

  useEffect(() => {
    getGroupCourses();
  }, [filters, state]);

  useEffect(() => {
    getGroup();
  }, [params]);

  const groupId = params.id || '';

  return (
    <PageContainer>
      <Box sx={{ px: matchDownLG ? 0 : 4, gap: 1 }}>
        {titleLoading ? (
          <Skeleton variant="rectangular" height={70} width="50%" />
        ) : (
          <Box mb={2}>
            {title.location && (
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '500',
                  fontSize: matchDownXS ? '12px' : '24px',
                  lineHeight: '130%',
                  color: '#162738',
                }}
              >
                {title.location}
              </Box>
            )}
            <Box
              sx={{
                fontFamily: 'Raleway',
                fontWeight: 600,
                fontSize: matchDownXS ? '20px' : '40px',
                color: '#162738',
                lineHeight: '130%',
              }}
            >
              {title.groupName}
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mb: 1 }}>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            <Box
              sx={{
                p: 4,
                borderRadius: '15px',
                backgroundColor: 'white',
                flex: '1 0 auto',
                display: 'flex',
                ...(!matchDownMD && { maxWidth: '545px' }),
                ...(matchDownMD && { width: '100%' }),
                flexDirection: 'column',
              }}
            >
              <TopLearners groupId={groupId} />
            </Box>
            <Box
              sx={{
                p: 4,
                borderRadius: '15px',
                backgroundColor: 'white',
                ...(!matchDownMD && { maxWidth: '545px' }),
                ...(matchDownMD && { width: '100%' }),
                flex: '1 0 auto',
              }}
            >
              <TopCourses groupId={groupId} />
            </Box>
            <Box
              sx={{
                flex: matchDownLg ? '1' : '0.3',
                width: '100%',
              }}
            >
              <TotalCourses groupId={groupId} />
            </Box>
          </Box>
          <Stats groupId={groupId} />
        </Box>

        <DataGrid
          rows={gridData.rows}
          columns={columns}
          filters={filterModel}
          handleExport={exportGroups}
          handleFilterChange={setFilters}
          totalRows={gridData.totalRows}
          loading={loading.courses}
          onRowClick={(row) => {
            navigate(`/reports/groups/${params.id}/course/${row.id}`);
          }}
        />
      </Box>
    </PageContainer>
  );
};

const GroupCourseReport = () => (
  <ControlledDataGrid>
    <GroupCourseReportGrid />
  </ControlledDataGrid>
);

export default GroupCourseReport;
