/* eslint-disable */
import {
  memo,
  useCallback,
  useMemo,
  useState,
  ReactNode,
  useEffect,
} from 'react';

import LiveChatWidgetContext, {
  LIVECHATWIDGET_INITIAL_STATE,
} from './contexts/LiveChatWidgetProviderContext';

const LiveChatWidgetProvider = ({ children }: { children: ReactNode }) => {
  const [liveChatWidgetState, setLiveChatWidgetState] = useState(
    LIVECHATWIDGET_INITIAL_STATE
  );

  const value = useMemo(
    () => ({
      liveChatWidgetState,
      setLiveChatWidgetState,
    }),
    [liveChatWidgetState, setLiveChatWidgetState]
  );

  return (
    <LiveChatWidgetContext.Provider value={value}>
      {children}
    </LiveChatWidgetContext.Provider>
  );
};

export default memo(LiveChatWidgetProvider);
