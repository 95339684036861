import { useContext } from 'react';
import LiveChatWidgetContext from '../contexts/LiveChatWidgetProviderContext';

export const useLiveChatWidgetDispatcher = () => {
  const { liveChatWidgetState, setLiveChatWidgetState } = useContext(
    LiveChatWidgetContext
  );

  return {
    liveChatWidgetState,
    setLiveChatWidgetState,
  };
};
