export const getDescriptionEllipsis = (
  description?: string,
  maxLength = 100
): string => {
  if (!description) {
    return '';
  }

  if (description.length > maxLength) {
    let _truncatedDescription = description.substring(0, maxLength);

    _truncatedDescription = _truncatedDescription.concat('...');

    return _truncatedDescription;
  }

  return description;
};

export const getCourseStatuses = () => {
  return [
    {
      label: 'To-Do',
      value: 'To-Do',
    },
    {
      label: 'In-Progress',
      value: 'In-Progress',
    },
    {
      label: 'Completed',
      value: 'Completed',
    },
    {
      label: 'Failed',
      value: 'Failed',
    },
  ];
};

export const deleteAttribute = (object: any, key: string) => {
  delete object[key];
};

export const decimalToPercentage = (decimal: number, fractionDigits = 2) =>
  Number((decimal * 100).toFixed(fractionDigits));

export const toFixedIfDecimal = (value: number) =>
  Number.isInteger(value) ? value : value.toFixed(2);

export const formatPercentage = (decimal: number, fractionDigits?: number) =>
  `${formatPercentageSymbol(decimalToPercentage(decimal, fractionDigits))}`;

export const formatPercentageValue = (value: number, fractionDigits = 2) =>
  `${formatPercentageSymbol(Number(value.toFixed(fractionDigits)))}`;

export const formatPercentageSymbol = (value: number) => `${value}%`;

export const formatNumber = (value: number | string) => {
  return Intl.NumberFormat('en-US').format(Number(value));
};

export const formatCurrency = (value: string | number) => {
  const _value = value ? Number(value) : 0;

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    signDisplay: 'auto',
  }).format(_value);
};

export const is20PercentUnder = (number1: number, number2: number) => {
  const twentyPercentOfGivenNumber = number1 * 0.8;
  return number2 < twentyPercentOfGivenNumber;
};

export const transformEmptyStringIntoUndefined = (
  value: any,
  originalValue: any
) => {
  if (String(originalValue).trim() === '' || isNaN(originalValue)) {
    return undefined;
  }

  return value;
};

export const formatPercentageSymbolWithPrecision = (value: number) => {
  if (!value) {
    return '-';
  }

  if (value >= 100) {
    // If the value is 100 or more, return '100%' directly.
    return '100%';
  } else if (value < 1 && value !== 0) {
    // If the value is less than 1 but not 0, use toPrecision to avoid trailing zeros.
    return `${parseFloat(value.toPrecision(2))}%`;
  } else {
    // For other values, use toFixed to ensure two decimal places.
    return `${value.toPrecision(2)}%`;
  }
};

export const calculatePercentage = (
  numerator: number,
  denominator: number
): string => {
  if (numerator == 0 || denominator == 0) {
    return '-';
  }

  const _percentage = numerator / denominator;

  if (_percentage === 1) {
    return '100%';
  }

  return `${(_percentage * 100).toPrecision(2)}%`;
};

export const concatWithDelimiter = (arr: string[], delimiter: string) => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return ''; // Return empty string if array is empty or not valid
  } else if (arr.length === 1) {
    return arr[0]; // Return the only element if there is only one
  } else {
    return arr.join(delimiter); // Join array elements with delimiter
  }
};

export const isEmpty = (str: string | null) => !str?.length;

export const delay = (ms: any) => new Promise((res) => setTimeout(res, ms));

export const isObject = (value: any) =>
  typeof value === 'object' && value !== null;

export const isArray = (value: any) => Array.isArray(value);
