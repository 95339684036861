import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import AuthWrapper from '../components/AuthWrapper';
import Authentication from '../fragments/login/Authentication';
import AuthSSO from '../fragments/login/AuthSSO';
import RightDescriptionContent from '../fragments/rightDescriptionContent';

type AuthMethod = 'credentials' | 'sso';

const Login = () => {
  const [searchParams] = useSearchParams();
  const [authMethod, setAuthMethod] = useState<AuthMethod>('credentials');

  // Check for SSO parameter on mount
  useEffect(() => {
    const ssoParam = searchParams.get('sso');
    if (ssoParam === 'true') {
      setAuthMethod('sso');
    }
  }, [searchParams]);

  const handleSwitchToSSO = () => setAuthMethod('sso');
  const handleSwitchToCredentials = () => setAuthMethod('credentials');

  const leftContent = authMethod === 'credentials' ? (
    <Authentication 
      handleSwitchToSSO={handleSwitchToSSO}
    />
  ) : (
    <AuthSSO 
      handleSwitchToCredentials={handleSwitchToCredentials}
    />
  );

  return (
    <AuthWrapper
      leftContent={leftContent}
      rightContent={<RightDescriptionContent />}
    />
  );
};

export default Login;
