import React, { useEffect, useState } from 'react';
import { Avatar, Box, Grid, Skeleton } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { lighten } from 'polished';

import httpRoutes from 'utils/httpRoutes';

import { useCallService } from 'hooks';

import Icon from '../../../../../icons';
import Counter from '../../../shared/Counter';

type ActivityStats = {
  avgGrade: number;
  totalLearners: number;
  totalCompletions: number;
  hoursOfEngagement: number;
};

const colorsByIndex = ['#FF5572', '#199CC5'];

const labels = ['Not started', 'Completed'];

const ActivityStats = ({
  courseId,
  groupId,
  startDate,
  endDate,
}: {
  courseId: string;
  groupId: string;
  startDate?: string;
  endDate?: string;
}) => {
  const { callService } = useCallService();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<ActivityStats>({
    avgGrade: 0,
    totalLearners: 0,
    totalCompletions: 0,
    hoursOfEngagement: 0,
  });
  const [chartData, setChartData] = useState<{
    labels: string[];
    datasets: any[];
  }>({ labels: [], datasets: [] });

  const getStats = async () => {
    try {
      const filters = new Map();

      if (startDate) {
        filters.set('startDate', startDate);
      }

      if (endDate) {
        filters.set('endDate', endDate);
      }

      if (groupId && courseId) {
        const { response } = await callService({
          resource:
            httpRoutes.reporting.course.getCreatorCourseGroupLearnersReportStats(
              {
                courseId,
                groupId,
                filters,
              }
            ),
        });

        if (response) {
          const {
            averageScore,
            totalLearners,
            activeCompletions,
            hoursOfEngagement,
          } = response;

          setStats({
            avgGrade: averageScore,
            totalLearners,
            totalCompletions: Number(activeCompletions),
            hoursOfEngagement: hoursOfEngagement,
          });

          const data = [
            totalLearners - Number(activeCompletions),
            Number(activeCompletions),
          ];

          const doughnutData = {
            labels: ['Not started', 'Completed'],
            datasets: [
              {
                data,
                backgroundColor: colorsByIndex,
                borderWidth: 1,
              },
            ],
          };

          setChartData(doughnutData);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  if (loading) {
    return (
      <Grid
        container
        rowGap={4}
        sx={{
          p: 4,
          borderRadius: '15px',
          backgroundColor: 'white',
        }}
      >
        <Skeleton variant="rectangular" height="400px" width="100%" />
      </Grid>
    );
  }

  return (
    <Grid
      container
      rowGap={4}
      sx={{
        p: 4,
        borderRadius: '15px',
        backgroundColor: 'white',
      }}
    >
      <Grid container item xs={12} md={6} rowGap={5} justifyContent="center">
        <Grid item xs={12}>
          <Box
            sx={{
              mb: 1,
              fontFamily: 'Raleway',
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '14px',
              textTransform: 'uppercase',
              color: '#205072',
            }}
          >
            Activity
          </Box>
          <Box
            sx={{ height: '93%', display: 'flex', justifyContent: 'center' }}
          >
            <Doughnut
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
              }}
              data={chartData}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}
        >
          {labels.map((label, index) => {
            return (
              <Box
                key={label}
                sx={{ display: 'flex', gap: 1, alignItems: 'center' }}
              >
                <Box
                  sx={{
                    height: '10px',
                    width: '10px',
                    backgroundColor: colorsByIndex[index],
                    borderRadius: '10px',
                  }}
                />
                <Box
                  sx={{
                    fontFamily: 'Roboto',
                    fontWeight: '400',
                    fontSize: { md: '14px', sm: '14px', xs: '11px' },
                    textAlign: 'center',
                    lineHeight: '16px',
                    color: '#343434',
                  }}
                >
                  {label}
                </Box>
              </Box>
            );
          })}
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        md={6}
        rowGap={5}
        justifyContent="center"
        sx={{ justifyContent: 'center', display: 'flex' }}
      >
        <Grid item xs={12}>
          <Counter
            label="Average Grade"
            counter={stats.avgGrade}
            isPercentage={true}
            color="#205072"
          />
        </Grid>

        <Grid container item xs={12} gap={4} justifyContent="center">
          <Grid container item sx={{ width: '300px' }}>
            <Grid item xs={6}>
              <Avatar
                sx={{
                  backgroundColor: lighten(0.3, '#12D1D0'),
                  opacity: '0.9',
                  width: 62,
                  height: 62,
                }}
              >
                <Icon name="three-users" size="extraLarge" fill="#12D1D0" />
              </Avatar>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '14px',
                  textTransform: 'uppercase',
                }}
              >
                Learners
              </Box>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: '40px',
                  lineHeight: '56px',
                }}
              >
                {stats.totalLearners}
              </Box>
            </Grid>
          </Grid>
          <Grid container item sx={{ width: '300px' }}>
            <Grid item xs={6}>
              <Avatar
                sx={{
                  backgroundColor: lighten(0.3, '#199BC5'),
                  opacity: '0.9',
                  width: 62,
                  height: 62,
                }}
              >
                <Icon
                  name="completed-outlined"
                  size="extraLarge"
                  fill="#199BC5"
                />
              </Avatar>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '14px',
                  textTransform: 'uppercase',
                }}
              >
                Completions
              </Box>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: '40px',
                  lineHeight: '56px',
                }}
              >
                {stats.totalCompletions}
              </Box>
            </Grid>
          </Grid>
          <Grid container item sx={{ width: '300px' }}>
            <Grid item xs={6}>
              <Avatar
                sx={{
                  backgroundColor: lighten(0.3, '#199BC5'),
                  opacity: '0.9',
                  width: 62,
                  height: 62,
                }}
              >
                <Icon name="clock" size="extraLarge" fill="#199BC5" />
              </Avatar>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'center' }}>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '14px',
                  textTransform: 'uppercase',
                }}
              >
                Hours of Engagement
              </Box>
              <Box
                sx={{
                  fontFamily: 'Raleway',
                  fontWeight: '600',
                  fontSize: '40px',
                  lineHeight: '56px',
                }}
              >
                {stats.hoursOfEngagement}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ActivityStats;
