import { School } from '@mui/icons-material';
import { Box, Skeleton } from '@mui/material';

const RightAuthDescriptionContent = ({
  communityLoading = false,
  communityName,
}: {
  communityLoading?: boolean;
  communityName?: string;
}) => {
  return (
    <>
      {communityLoading ? (
        <Skeleton
          variant="rectangular"
          height="60px"
          width="100%"
          sx={{ mb: 3 }}
        />
      ) : (
        communityName && (
          <Box
            mb={3}
            sx={{
              color: '#162738',
              fontFamily: 'Raleway',
              fontSize: '26px',
              lineHeight: '30px',
              textAlign: 'center',
            }}
          >
            Welcome to the{' '}
            <span style={{ fontWeight: '600' }}>{communityName}</span>{' '}
            community!
          </Box>
        )
      )}
      <Box
        sx={{
          color: '#162738',
          fontFamily: 'Raleway',
          fontWeight: '600',
          fontSize: communityName ? '20px' : '32px',
          lineHeight: '45px',
        }}
      >
        Join tens of thousands of industry professionals advancing their
        knowledge and skill sets through Seed Talent!
      </Box>
      <hr
        style={{ border: '2px solid #162738', margin: '20px 0', width: '100%' }}
      />
      {communityLoading ? (
        <Skeleton
          variant="rectangular"
          height="166px"
          width="100%"
          sx={{ mb: 3, mt: 2 }}
        />
      ) : (
        <>
          <Box
            sx={{
              color: '#162738',
              fontFamily: 'Arial',
              fontWeight: '400',
              fontSize: '16px',
              lineHeight: '32px',
              mt: 2,
            }}
          >
            Seed Talent centralizes your professional development for all
            compliance, company info, industry foundations, and product training
            directly from the top brands you sell today!
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              marginTop: '20px',
            }}
          >
            <School
              sx={{ color: '#205072', fontSize: '60px', margin: '0 auto' }}
            />
          </Box>
          <Box
            sx={{
              color: '#162738',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '32px',
              my: 2,
            }}
          >
            Earn rewards, grow your skills, and advance your career-all while
            better serving your customers!
          </Box>
        </>
      )}
    </>
  );
};

export default RightAuthDescriptionContent;
