import AuthWrapper from '../components/AuthWrapper';
import CompleteRegistrationContent from '../fragments/completeRegistration/CompleteRegistration';
import RightDescriptionContent from '../fragments/rightDescriptionContent';

const CompleteRegistration = () => {
  return (
    <AuthWrapper
      leftContent={<CompleteRegistrationContent />}
      rightContent={<RightDescriptionContent />}
    />
  );
};

export default CompleteRegistration;
