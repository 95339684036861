import React, { useEffect, useState } from 'react';

import { AddIcon, DeleteIcon, EditIcon } from 'material-icons';

import { Box } from '@mui/material';

import DataGrid, {
  ControlledDataGrid,
  DataGridModel,
  useDataGridState,
} from 'components/dataGrid/components/DataGrid';
import { FilterType, FilterState } from 'components/filters/filterTypes';
import { DataGridColumnProps } from 'components/dataGrid/components/DataGridColumn';
import IconButton from 'components/buttons/IconButton';
import PageContainer from 'components/PageContainer';
import Button from 'components/buttons/Button';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import httpRoutes from 'utils/httpRoutes';
import { useCallService } from 'hooks';

import AssignCourse from './dialogs/assignCourse/AssignCourse';
import Assignment from './dialogs/Assignment';
import DeleteAssignment from './dialogs/DeleteAssignment';
import AssignCourseProvider from 'providers/AssignCourseProvider/AssignCourseProvider';
import { useLiveChatWidgetDispatcher } from 'providers/LiveChatWidgetProvider/hooks/useLiveChatWidgetDispatcher';
import { AssignmentType } from 'providers/AssignCourseProvider/contexts/AssignCourseContext';

const AssignmentListGrid = ({
  organizationId,
  isOrganizationManager,
}: {
  organizationId: string;
  isOrganizationManager: boolean;
}) => {
  const { state } = useDataGridState();
  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();
  const { setLiveChatWidgetState } = useLiveChatWidgetDispatcher();

  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState<FilterState>(new Map());
  const [gridData, setGridData] = useState<DataGridModel>({
    rows: [],
    totalRows: 0,
  });

  const filterModel: FilterType[] = [
    {
      id: 'name',
      type: 'text',
      label: 'Course Name',
    },
  ];

  const columns = (
    showDeleteDialog: any,
    showAssignmentDialog: any
  ): DataGridColumnProps[] => [
    {
      headerName: 'Assignment',
      field: 'name',
      sortable: true,
      ColumnComponent: (params: any) => {
        return (
          <Box
            sx={{
              fontFamily: 'Roboto',
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '150%',
              color: '#508AC2',
              cursor: 'pointer',
            }}
            onClick={() => {
              showAssignmentDialog(params.row);
            }}
          >
            {params.row.name}
          </Box>
        );
      },
    },
    {
      headerName: 'Assigned Type',
      field: 'assignmentType',
      sortable: false,
      ColumnComponent: (params: any) => {
        return (
          <>
            {params.row.assignmentType === AssignmentType.CurrentAndFuture
              ? 'Current and Future'
              : params.row.assignmentType}
          </>
        );
      },
    },
    {
      headerName: 'Assigned Date',
      field: 'assignmentDate',
      sortable: false,
      type: 'date',
      ColumnComponent: (params: any) => {
        return <>{new Date(params.row.assignmentDate).toLocaleDateString()}</>;
      },
    },
    {
      headerName: 'Users',
      field: 'usersCount',
      sortable: false,
      type: 'number',
    },
    {
      headerName: 'Courses',
      field: 'coursesCount',
      sortable: false,
      type: 'number',
    },
    {
      headerName: 'Action',
      field: 'action',
      filterable: false,
      sortable: false,
      flex: 0.15,
      ColumnComponent: (params: any) => {
        return (
          <>
            <IconButton onClick={() => showAssignCourseDialog(params.row.id)}>
              <EditIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => showDeleteDialog(params.row)}>
              <DeleteIcon color="error" />
            </IconButton>
          </>
        );
      },
    },
  ];

  const getAssignmentList = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.assignCourse.getAll({
          organizationId,
          params: { ...state },
          filters: filters,
        }),
      });

      if (response) {
        setGridData({
          rows: response.items,
          totalRows: response.totalRows,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const showAssignCourseDialog = (organizationAssignmentId?: number) => {
    setLiveChatWidgetState({ visibility: 'hidden' });
    showDialog({
      content: (
        <AssignCourseProvider>
          <AssignCourse
            organizationId={organizationId}
            organizationAssignmentId={organizationAssignmentId}
            isOrganizationManager={isOrganizationManager}
            onSuccess={getAssignmentList}
          />
        </AssignCourseProvider>
      ),
      fullScreen: true,
      enableBackdropClose: false,
    });
  };

  const showDeleteDialog = (assignment: any) => {
    const { id, name } = assignment;

    showDialog({
      content: (
        <AssignCourseProvider>
          <DeleteAssignment
            onSuccess={getAssignmentList}
            organizationAssignmentId={id}
            name={name}
          />
        </AssignCourseProvider>
      ),
      size: 'md',
    });
  };

  const showAssignmentDialog = (assignment: any) => {
    const { id } = assignment;

    showDialog({
      content: <Assignment assignmentId={id} />,
      fullScreen: true,
    });
  };

  useEffect(() => {
    getAssignmentList();
  }, [filters, state]);

  return (
    <PageContainer
      showBackButton={false}
      pageAction={
        <Button
          label="Assign Course"
          onClick={() => showAssignCourseDialog()}
          startIcon={<AddIcon />}
        />
      }
      isSecondary
      elevation={0}
    >
      <DataGrid
        rows={gridData.rows}
        columns={columns(showDeleteDialog, showAssignmentDialog)}
        filters={filterModel}
        handleFilterChange={setFilters}
        totalRows={gridData.totalRows}
        loading={loading}
      />
    </PageContainer>
  );
};

const AssignmentList = ({
  organizationId,
  isOrganizationManager = false,
}: {
  organizationId: string;
  isOrganizationManager?: boolean;
}) => (
  <ControlledDataGrid>
    <AssignmentListGrid
      organizationId={organizationId}
      isOrganizationManager={isOrganizationManager}
    />
  </ControlledDataGrid>
);

export default AssignmentList;
