import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuthDispatcher } from 'providers/AuthProvider/hooks/useAuthDispatcher';

import useCallService from 'hooks/useCallService';

import httpRoutes from 'utils/httpRoutes';

import AuthWrapper from '../components/AuthWrapper';
import CircularProgressWithLabel from 'components/CircularProgressWithLabel';

import CompleteValidationFragment from '../fragments/passwordLess/CompleteValidation';
import RightDescriptionContent from '../fragments/rightDescriptionContent';

const CompleteValidation = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<any>({});

  const { callService } = useCallService();
  const { login } = useAuthDispatcher();

  const navigate = useNavigate();

  const { organizationId = '', courseReference = '', token = '' } = useParams();
  const isOnDashboard = location.pathname.includes('dashboard');

  const onSuccess = () => {
    if (isOnDashboard) {
      navigate(`/organization/${organizationId}/dashboard`);
    } else {
      navigate(`/organization/${organizationId}/course/${courseReference}`);
    }
  };

  useEffect(() => {
    const validateToken = async () => {
      const { response, error } = await callService({
        resource:
          httpRoutes.authentication.organization.validateEmailToken(token),
      });

      if (response && response.token?.length > 0) {
        login(response);
        onSuccess();
      } else if (response) {
        setUser(response.user);
      }

      if (error) {
        navigate(
          isOnDashboard
            ? `/organization/${organizationId}/dashboard/validate-email?expired=${true}`
            : `/organization/${organizationId}/course/${courseReference}/validate-email?expired=${true}`
        );
      }

      setLoading(false);
    };

    validateToken();
  }, [token]);

  if (loading) {
    return <CircularProgressWithLabel />;
  } else {
    return (
      <AuthWrapper
        leftContent={
          <CompleteValidationFragment
            defaultValues={user}
            onSuccess={onSuccess}
          />
        }
        rightContent={<RightDescriptionContent />}
      />
    );
  }
};

export default CompleteValidation;
