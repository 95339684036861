import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Grid } from '@mui/material';

import { useCallService } from 'hooks';

import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import { useAuthDispatcher } from 'providers/AuthProvider/hooks/useAuthDispatcher';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

import Form, { UserFormInput } from '../forms/UserForm';
import PublicUserForm, { PublicUserFormInput } from '../forms/PublicUserForm';

import httpRoutes from 'utils/httpRoutes';
import MergeUser from './MergeUser';

const EditUser = ({
  onSuccess,
  hideDialog,
  userId,
}: {
  onSuccess: VoidFunction;
  hideDialog: VoidFunction;
  userId: string;
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [defaultValues, setDefaultValues] = useState<
    UserFormInput | PublicUserFormInput
  >();
  const { callService } = useCallService();
  const { login, updateUser } = useAuthDispatcher();
  const {
    authState: { user, isAdmin, isAlias },
  } = useAuthState();

  const { showDialog } = useDialogDispatcher();
  const navigate = useNavigate();

  const logInAs = async () => {
    const { response } = await callService({
      resource: httpRoutes.userManagement.logInAsUser(userId),
      successMessage: `Logged in as ${defaultValues?.firstName} ${defaultValues?.lastName}.`,
    });

    if (response) {
      hideDialog();
      login(response);

      navigate('/', { replace: true });
    }
  };

  const mergeUser = async () => {
    // show dialog with user selection
    showDialog({
      size: 'sm',
      content: <MergeUser user={defaultValues} onSuccess={onSuccess} />,
    });
  };

  const onSubmit = async (values: UserFormInput | PublicUserFormInput) => {
    setIsSubmitting(true);
    const { response } = await callService({
      resource: httpRoutes.userManagement.updateUser(values),
      successMessage: 'User updated successfully!',
    });

    let facilitatorGroupsIds = [...user.facilitatorGroupsIds];

    if (typeof values.newUserGroups !== 'undefined') {
      facilitatorGroupsIds = values.newUserGroups
        .filter((userGroup: any) => userGroup.facilitator || userGroup.manager)
        .map((userGroup: any) => userGroup.groupId);
    }

    if (response) {
      onSuccess();
      if (user.id === values.id) {
        const updatedUser = { ...user, facilitatorGroupsIds };
        updateUser({ user: updatedUser });
      }
    }

    setIsSubmitting(false);
  };

  useEffect(() => {
    const getItem = async () => {
      const { response } = await callService({
        resource: httpRoutes.userManagement.getOne(userId),
      });

      if (response) {
        const { userGroups, ...rest } = response;

        const newUserGroups = userGroups.map((userGroup: any) => {
          const {
            group: { name },
            groupId,
            isPrimary,
            notify,
            facilitator,
            ddl,
            manager,
          } = userGroup;

          return {
            name,
            groupId,
            isPrimary,
            notify,
            facilitator,
            ddl,
            manager,
          };
        });

        setDefaultValues({
          ...rest,
          newUserGroups,
          ssoEnabled: response.sso?.isSsoEnabled || false
        });
        setIsLoading(false);
      }
    };

    getItem();
  }, [userId]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  //TODO: Wrap the render logic into a HOC to do not render this component if the user is an alias
  if (
    isAlias &&
    !user.managerOrganizationId &&
    user.facilitatorGroupsIds.length === 0
  ) {
    return <div></div>;
  }

  return (
    <div>
      {isAdmin && (
        <Grid container justifyContent="flex-end" spacing={1}>
          <Button onClick={logInAs} variant="outlined" sx={{ m: 1 }}>
            Log In As User
          </Button>
          <Button onClick={mergeUser} variant="outlined" sx={{ m: 1 }}>
            Merge User
          </Button>
        </Grid>
      )}

      {isAdmin && (
        <Form
          defaultValues={defaultValues as UserFormInput}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      )}

      {!isAdmin && (
        <PublicUserForm
          defaultValues={defaultValues as PublicUserFormInput}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      )}
    </div>
  );
};

export default EditUser;
