import AuthWrapper from '../components/AuthWrapper';
import RightDescriptionContent from '../fragments/rightDescriptionContent';
import SignUpContent from '../fragments/signup/SignUp';

const SignUp = () => (
  <AuthWrapper
    leftContent={<SignUpContent />}
    rightContent={<RightDescriptionContent />}
  />
);

export default SignUp;
