import { createContext } from 'react';

export type LiveChatWidgetStateType = {
  visibility: 'maximized' | 'minimized' | 'hidden';
};

export const LIVECHATWIDGET_INITIAL_STATE: LiveChatWidgetStateType = {
  visibility: 'minimized',
};

const LiveChatWidgetContext = createContext<{
  liveChatWidgetState: LiveChatWidgetStateType;
  setLiveChatWidgetState: (state: LiveChatWidgetStateType) => void;
}>({
  liveChatWidgetState: LIVECHATWIDGET_INITIAL_STATE,
  setLiveChatWidgetState: () => null,
});

export default LiveChatWidgetContext;
