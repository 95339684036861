import { useState, useEffect } from 'react';
import Button from 'components/buttons/Button';
import PageContainer from 'components/PageContainer';
import { useCallService } from 'hooks';
import { SecurityIcon, AddIcon } from 'material-icons';
import { useDialogDispatcher } from 'providers/DialogProvider/hooks/useDialogDispatcher';
import OrganizationSSOConfigurationForm from '../forms/OrganizationSSOConfigurationForm';
import httpRoutes from 'utils/httpRoutes';
import { Typography, Paper, Grid } from '@mui/material';

interface SSOConfigurationData {
  entryPoint: string;
  issuer: string;
  certificate: string;
}

const SSOConfigurationContent = ({ organizationId }: { organizationId: string }) => {
  const { callService } = useCallService();
  const { showDialog } = useDialogDispatcher();
  const [loading, setLoading] = useState(false);
  const [ssoConfig, setSsoConfig] = useState<SSOConfigurationData | null>(null);

  const getSSOConfiguration = async () => {
    setLoading(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.organizations.getSingleSignOnConfiguration(organizationId),
      });

      if (response) {
        setSsoConfig(response);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const showConfigureDialog = () => {
    showDialog({
      content: (
        <OrganizationSSOConfigurationForm
          organizationId={organizationId}
          onSuccess={getSSOConfiguration}
        />
      ),
      size: 'sm',
    });
  };

  useEffect(() => {
    getSSOConfiguration();
  }, []);

  return (
    <PageContainer
      showBackButton={false}
      title="SSO Configuration"
      icon={<SecurityIcon sx={{ fontSize: '24px' }} />}
      pageAction={
        <Button
          label={ssoConfig ? 'Update SSO' : 'Configure SSO'}
          onClick={showConfigureDialog}
          startIcon={<AddIcon />}
        />
      }
      isSecondary
      elevation={0}
    >
      <Paper sx={{ p: 3 }}>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : ssoConfig ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Identity Provider SSO URL</Typography>
              <Typography>{ssoConfig.entryPoint}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Identity Provider Issuer</Typography>
              <Typography>{ssoConfig.issuer}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Certificate</Typography>
              <Typography
                sx={{
                  maxWidth: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {ssoConfig.certificate}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <Typography>No SSO configuration found. Click Configure SSO to set up.</Typography>
        )}
      </Paper>
    </PageContainer>
  );
};

const SSOConfiguration = ({ organizationId }: { organizationId: string }) => (
  <SSOConfigurationContent organizationId={organizationId} />
);

export default SSOConfiguration;
