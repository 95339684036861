import AuthWrapper from '../../authentication/components/AuthWrapper';
import RightDescriptionContent from '../../authentication/fragments/rightDescriptionContent';

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallService } from 'hooks';
import httpRoutes from 'utils/httpRoutes';
import { Box, Grid } from '@mui/material';
import Button from 'components/buttons/Button';

const ApproveUserRequestContent = () => {
  const { communityId = '', userId = '' } = useParams();
  const navigate = useNavigate();

  const [groupLoading, setGroupLoading] = useState(true);
  const [group, setGroup] = useState<any>(null);

  const { callService } = useCallService();

  const confirmJoin = async () => {
    await callService({
      resource: httpRoutes.groups.approveMemberRequest(communityId, userId),
      successMessage: 'Success! User is now a member!',
    });
  };

  useEffect(() => {
    const getGroup = async () => {
      try {
        const { response } = await callService({
          resource: httpRoutes.groups.getOne(communityId),
        });

        if (response) {
          setGroup(response);
        }
      } finally {
        setGroupLoading(false);
      }
    };

    getGroup();
    confirmJoin();
  }, []);

  const goHome = () => {
    navigate('/');
  };

  return (
    <Box>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px',
          }}
        >
          User has been granted access to the community.
        </Box>
      </Grid>
      <Grid item xs={12} mb={1}>
        <Button
          type="submit"
          label="Go Home"
          onClick={goHome}
          sx={{
            textTransform: 'uppercase',
            fontFamily: 'Raleway',
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '24px',
            background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)',
          }}
        />
      </Grid>
    </Box>
  );
};

const ApproveUserRequest = () => {
  return (
    <AuthWrapper
      leftContent={<ApproveUserRequestContent />}
      rightContent={<RightDescriptionContent />}
    />
  );
};

export default ApproveUserRequest;
