import { LiveChatWidget } from '@livechat/widget-react';

import { useLiveChatWidgetDispatcher } from 'providers/LiveChatWidgetProvider/hooks/useLiveChatWidgetDispatcher';

function LiveChatWidgetComponent() {
  const { liveChatWidgetState } = useLiveChatWidgetDispatcher();

  return (
    <LiveChatWidget
      license="13069899"
      visibility={liveChatWidgetState.visibility}
    />
  );
}

export default LiveChatWidgetComponent;
