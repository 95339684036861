import { MouseEvent, useState } from 'react';

import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';

import DisplayText from 'components/DisplayText';
import {
  ExpandLessIcon,
  ExpandMoreIcon,
  ArrowBackRoundedIcon,
  MenuIcon,
} from 'material-icons';

import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';

import LeftNavigationItem from 'layout/MainLayout/LeftNavigationDrawer/LeftNavigationItem';
import LeftNavigationCollapsible from 'layout/MainLayout/LeftNavigationDrawer/LeftNavigationCollapsible';

import {
  adminReportingLinks,
  courseManagementLinks,
  facilitatorLinks,
  facilitatorCommunitiesSubItemsLinks,
  organizationManagementLinks,
  profileLinks,
  reportingLinks,
  userLinks,
  facilitatorDdlLinks,
  userDdlLinks,
  adminPanelLinks,
  courseBuilderLinks,
  organizationAdminLinks,
} from 'layout/MainLayout/LeftNavigationDrawer/leftNavigationLinks';

import { useNavigate } from 'react-router-dom';
import { useAuthDispatcher } from 'providers/AuthProvider/hooks/useAuthDispatcher';
import { useTheme } from '@mui/material/styles';
import Icon from 'icons';

const drawerWidth = 291;

const mobileDrawerStyle = {
  position: 'absolute',
};

const UserProfile = ({
  handleDrawerToggle,
  drawerIsOpen = false,
}: {
  handleDrawerToggle: () => void;
  drawerIsOpen?: boolean;
}) => {
  const {
    authState: {
      user: { firstName = '', lastName = '', email },
      organization: { id: organizationId },
    },
  } = useAuthState();
  const router = useNavigate();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!drawerIsOpen && !matchDownSM) {
    return (
      <Box
        sx={{
          p: '10px 30px 30px 14px',
          width: 'fit-content',
          color: 'white',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: '10px 30px 30px 30px',
        gap: '16px',
        visibility: drawerIsOpen ? 'visible' : 'hidden',
      }}
    >
      <Box sx={{ display: 'flex', color: 'white' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
        >
          <ArrowBackRoundedIcon />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Box
          onClick={anchorElUser ? handleCloseUserMenu : handleOpenUserMenu}
          sx={{
            display: 'flex',
            color: 'white',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <DisplayText
              type="bodyMedium"
              variant="regular"
              text={`${firstName} ${lastName}`}
            />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {anchorElUser ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Box>
        </Box>
      </Box>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Menu
            sx={{ mt: '45px', zIndex: 9999 }}
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{
                width: 250,
                minWidth: 240,
                maxWidth: 290,
                pl: 1,
                pt: 2,
                mb: 1,
              }}
            >
              <Grid item>
                <Stack direction="row" spacing={1.25} alignItems="center">
                  <Stack>
                    <Typography variant="caption">Signed in as</Typography>
                    <Typography variant="h4">{email}</Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Divider />
            {profileLinks.map((item) => {
              const { icon: Icon } = item;

              return (
                <MenuItem
                  key={item.label}
                  onClick={() => {
                    handleCloseUserMenu();
                    if (matchDownMD) {
                      handleDrawerToggle();
                    }
                    router(item.link);
                  }}
                  sx={{ p: 1.25 }}
                >
                  <Icon />
                  <ListItemText>{item.label}</ListItemText>
                </MenuItem>
              );
            })}
          </Menu>
        </Grid>
      </Grid>
    </Box>
  );
};

const LeftNavigationDrawer = ({
  selectedOption,
  setSelectedOption,
  handleDrawerToggle,
  isDrawerOpen,
}: {
  selectedOption: string;
  setSelectedOption: (id: string, isAdmin?: boolean) => void;
  handleDrawerToggle: () => void;
  isDrawerOpen: boolean;
}) => {
  const { logout } = useAuthDispatcher();
  const theme = useTheme();
  const matchDownMD = useMediaQuery(theme.breakpoints.down('md'));

  const {
    authState: {
      user: {
        enrolledInDdl,
        ddl,
        facilitatorGroupsIds,
        managerGroupIds,
        managerOrganizationId,
        roles,
      },
      organization: { id: organizationId },
      isAdmin,
      permissions,
    },
  } = useAuthState();

  const isCourseBuilder = roles.includes('Course Builder');
  const isFacilitator = permissions.includes('communities:facilitator');
  const hasCourseAssignmentPermissions =
    (permissions.includes('assignments:write') &&
      permissions.includes('assignments:read')) ||
    managerGroupIds?.length > 0;

  const handleSelectedOption = (id: string, isAdmin?: boolean) => {
    if (matchDownMD) {
      handleDrawerToggle();
    }
    setSelectedOption(id, isAdmin);
  };

  return (
    <>
      {isDrawerOpen && matchDownMD && (
        <Box
          onClick={handleDrawerToggle}
          sx={{
            height: '100vh',
            width: '100%',
            backgroundColor: 'black',
            position: 'absolute',
            zIndex: 'drawer',
            opacity: 0.5,
          }}
        />
      )}

      <Box
        sx={{
          display: 'flex',
          width: isDrawerOpen ? drawerWidth : matchDownMD ? '0px' : '50px',
          flex: '0 0 auto',
          flexDirection: 'column',
          transition: 'width 0.3s',
          height: '100%',
          background:
            'linear-gradient(151.36deg, #054662 15.62%, #67B398 141.87%)',
          overflowX: 'hidden',
          overflowY: 'auto',
          zIndex: 'drawer',
          ...(matchDownMD && mobileDrawerStyle),
        }}
        component="nav"
      >
        <UserProfile
          handleDrawerToggle={handleDrawerToggle}
          drawerIsOpen={isDrawerOpen}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {isAdmin && (
            <>
              {isDrawerOpen && (
                <Box sx={{ p: '0px 30px 0px 20px', mb: 1 }}>
                  <DisplayText
                    type="sh1"
                    text="Management"
                    variant="semiBold"
                    style={{ color: 'white' }}
                  />
                </Box>
              )}
              {organizationManagementLinks.map((item) => (
                <LeftNavigationItem
                  drawerIsOpen={isDrawerOpen}
                  key={item.label}
                  item={item}
                  handleSelectedOption={handleSelectedOption}
                  selectedOption={selectedOption}
                />
              ))}
              <LeftNavigationCollapsible
                drawerIsOpen={isDrawerOpen}
                label="Course Admin"
                icon="course-admin"
                subItems={courseManagementLinks}
                handleSelectedOption={handleSelectedOption}
                selectedOption={selectedOption}
              />
              <LeftNavigationCollapsible
                drawerIsOpen={isDrawerOpen}
                label="Reports"
                icon="reports"
                subItems={adminReportingLinks}
                handleSelectedOption={handleSelectedOption}
                selectedOption={selectedOption}
              />
              <LeftNavigationCollapsible
                drawerIsOpen={isDrawerOpen}
                label="Data Driven Learning"
                icon="star"
                subItems={facilitatorDdlLinks(permissions)}
                handleSelectedOption={handleSelectedOption}
                selectedOption={selectedOption}
              />

              <LeftNavigationCollapsible
                drawerIsOpen={isDrawerOpen}
                label="Admin Panel"
                icon="dashboard"
                subItems={adminPanelLinks()}
                handleSelectedOption={handleSelectedOption}
                selectedOption={selectedOption}
              />
            </>
          )}
          {isDrawerOpen && (
            <Box sx={{ p: '0px 30px 0px 20px', my: 1 }}>
              <DisplayText
                type="sh1"
                text="Home"
                variant="semiBold"
                style={{ color: 'white' }}
              />
            </Box>
          )}
          {userLinks.map((item: any) => (
            <LeftNavigationItem
              key={item.label}
              drawerIsOpen={isDrawerOpen}
              item={item}
              handleSelectedOption={handleSelectedOption}
              selectedOption={selectedOption}
            />
          ))}

          {(isAdmin ||
            isCourseBuilder ||
            managerOrganizationId ||
            hasCourseAssignmentPermissions) && (
            <LeftNavigationCollapsible
              drawerIsOpen={isDrawerOpen}
              label="Course Management"
              icon="course-admin"
              subItems={courseBuilderLinks(
                hasCourseAssignmentPermissions,
                isCourseBuilder
              )}
              handleSelectedOption={handleSelectedOption}
              selectedOption={selectedOption}
            />
          )}

          {(isAdmin ||
            isCourseBuilder ||
            managerOrganizationId ||
            hasCourseAssignmentPermissions) && (
            <LeftNavigationItem
              drawerIsOpen={isDrawerOpen}
              key="organization-management"
              item={{
                label: 'Organization Management',
                link: `/organization-management/${organizationId}`,
                icon: 'organizations',
              }}
              handleSelectedOption={handleSelectedOption}
              selectedOption={selectedOption}
            />
          )}

          {(managerOrganizationId ||
            managerGroupIds?.length > 0 ||
            facilitatorGroupsIds?.length > 0 ||
            isAdmin) &&
            facilitatorLinks.map((item: any) => (
              <LeftNavigationItem
                drawerIsOpen={isDrawerOpen}
                key={item.label}
                item={item}
                handleSelectedOption={handleSelectedOption}
                selectedOption={selectedOption}
              />
            ))}

          {isFacilitator && (
            <LeftNavigationCollapsible
              drawerIsOpen={isDrawerOpen}
              label="Communities"
              icon="communities"
              checkNotifications={true}
              subItems={facilitatorCommunitiesSubItemsLinks}
              handleSelectedOption={handleSelectedOption}
              selectedOption={selectedOption}
            />
          )}

          <LeftNavigationCollapsible
            drawerIsOpen={isDrawerOpen}
            label="Reports"
            icon="reports"
            subItems={reportingLinks(permissions, facilitatorGroupsIds, managerGroupIds)}
            handleSelectedOption={handleSelectedOption}
            selectedOption={selectedOption}
          />

          {enrolledInDdl &&
            (ddl.basketSize ||
              ddl.houseBrands ||
              ddl.hasDutchieIntegration) && (
              <LeftNavigationCollapsible
                drawerIsOpen={isDrawerOpen}
                label="Store Performance"
                icon="sportFlag"
                subItems={userDdlLinks(ddl)}
                handleSelectedOption={handleSelectedOption}
                selectedOption={selectedOption}
              />
            )}

          <Box
            sx={{
              display: 'flex',
              gap: 1,
              flexDirection: 'column',
              overFlow: 'hidden',
              width: drawerWidth,
              p: isDrawerOpen ? '0px 30px 0px 20px' : 0,
            }}
          >
            <Box
              onClick={logout}
              sx={{
                display: 'flex',
                gap: 1,
                p: '10px',
                cursor: 'pointer',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Icon name="logout" />
              </Box>
              {isDrawerOpen && (
                <Box
                  sx={{
                    color: 'white',
                  }}
                >
                  <DisplayText
                    type="bodyMedium"
                    variant="regular"
                    text="Logout"
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        {/*</Box>*/}
        {/*</Box>*/}
        {/*</Box>*/}
      </Box>
    </>
  );
};

export default LeftNavigationDrawer;
