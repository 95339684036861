import { REACT_APP_API_URL, REACT_APP_CLOUDFRONT_URL } from 'config/config';
import CourseProps from 'types/courseTypes/CourseProps';

interface ScormFrameProps {
  course: CourseProps;
  userId: string;
  utrId?: string | undefined;
  utrData?: any;
}

const ScormFrame = (props: ScormFrameProps) => {
  const { course, userId, utrId, utrData } = props;
  const date = new Date().getTime();
  return (
    <iframe
      id="scormframe"
      height="100%"
      width="100%"
      style={{
        border: 'none',
        marginLeft: '0px !important',
        marginRight: '0px !important',
      }}
      src={`${REACT_APP_CLOUDFRONT_URL}/scorm-launcher.html?courseUrl=${encodeURIComponent(
        course.url as string
      )}&apiUrl=${encodeURIComponent(
        REACT_APP_API_URL
      )}&userId=${userId}&courseId=${course.id}&utrId=${utrId}&timeLimit=${
        course.timeLimit
      }&isAssessment=false
        &utrData=${utrData}
        &t=${date}`}
    />
  );
};

export default ScormFrame;
