import RightDescriptionContent from '../fragments/rightDescriptionContent';
import AuthWrapper from '../components/AuthWrapper';
import ResetPasswordContent from '../fragments/resetPassword/ResetPassword';

const ResetPassword = () => (
  <AuthWrapper
    leftContent={<ResetPasswordContent />}
    rightContent={<RightDescriptionContent />}
  />
);

export default ResetPassword;
