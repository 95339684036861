import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAuthDispatcher } from 'providers/AuthProvider/hooks/useAuthDispatcher';
import { useAuthState } from 'providers/AuthProvider/hooks/useAuthState';
import useCallService from 'hooks/useCallService';
import { useState } from 'react';
import httpRoutes from 'utils/httpRoutes';
import { Grid } from '@mui/material';
import Button from 'components/buttons/Button';
import { Box, CircularProgress } from '@mui/material';
import { REACT_APP_API_URL } from 'config/config';
import EmailFormField from 'components/formFields/EmailFormField2';

interface ISSOFormInputs {
  email: string;
}

const AuthSSO = ({ 
  redirectUrl = 'login',
  handleSwitchToCredentials,
}: { 
  redirectUrl?: string;
  handleSwitchToCredentials?: () => void;
}) => {
  const [isValidating, setIsValidating] = useState(false);
  const navigate = useNavigate();
  const location: any = useLocation();
  const { login } = useAuthDispatcher();
  const { callService } = useCallService();
  const {
    authState: { authenticated },
  } = useAuthState();

  const from: string = location?.state?.from?.pathname || '/';

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Must be a valid email')
      .max(255)
      .required('Email is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ISSOFormInputs>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validationSchema),
  });

  // Check for authentication state
  useEffect(() => {
    if (authenticated) {
      navigate(from, { replace: true });
    }
  }, [authenticated, from, navigate]);

  // Check for SAML response
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const samlResponse = urlParams.get('SAMLResponse');
    const relayState = urlParams.get('RelayState');
    
    if (samlResponse && relayState) {
      handleSamlResponse(samlResponse, relayState);
    }
  }, []);

  const handleSamlResponse = async (samlResponse: string, relayState: string) => {
    setIsValidating(true);
    try {
      const { response } = await callService({
        resource: httpRoutes.authentication.samlValidate({
          SAMLResponse: samlResponse,
          RelayState: relayState,
        }),
        showSnackbar: true,
      });

      if (response) {
        login(response);
        
        let _redirectUrl = from;
        if (redirectUrl) {
          _redirectUrl = `/${redirectUrl}`;
        }
        
        navigate(_redirectUrl, { replace: true });
      }
    } catch (error) {
      console.error('SAML authentication error:', error);
    } finally {
      setIsValidating(false);
    }
  };

  if (isValidating) {
    return (
      <Box 
        display="flex" 
        flexDirection="column"
        justifyContent="center" 
        alignItems="center" 
        minHeight="400px"
        gap={2}
      >
        <CircularProgress sx={{ color: 'white' }} />
        <Box sx={{ 
          color: 'white',
          fontFamily: 'Raleway',
          fontSize: '16px'
        }}>
          Logging in...
        </Box>
      </Box>
    );
  }

  return (
    <form 
      style={{ width: '100%' }}
      method="POST"
      action={`${REACT_APP_API_URL}/api/v1/auth/saml/login`}
    >
      <Grid item xs={12}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '40px',
            lineHeight: '130%',
          }}
        >
          Single Sign-On
        </Box>
      </Grid>
      <Grid item xs={12} mb={4}>
        <Box
          sx={{
            color: 'white',
            fontFamily: 'Raleway',
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '32px%',
          }}
        >
          Enter your email to continue
        </Box>
      </Grid>
      <input 
        type="hidden" 
        name="redirectUrl" 
        value={redirectUrl} 
      />
      <Grid item xs={12} mb={2}>
        <EmailFormField
          name="email"
          control={control}
          placeholder="Email"
          errors={errors.email}
          margin="dense"
        />
      </Grid>
      <Grid item xs={12} mb={1}>
        <Button
          type="submit"
          sx={{
            textTransform: 'uppercase',
            fontFamily: 'Raleway', 
            fontWeight: '700',
            fontSize: '20px',
            lineHeight: '24px',
            background: 'linear-gradient(180deg, #AB58BB 0%, #651E7C 100%)'
          }}
          label="Continue with SSO"
        />
      </Grid>
      <Grid container mt={3}>
        <Grid item xs={12} mb={1}>
          <Box
            sx={{
              color: 'white',
              textAlign: 'center',
              fontFamily: 'Raleway',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '32px',
            }}
          >
            Use Password Instead?
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            label="Sign in with Password"
            onClick={handleSwitchToCredentials}
            type="button"
            sx={{
              textTransform: 'uppercase',
              fontFamily: 'Raleway',
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '24px',
              background: 'linear-gradient(180deg, #113145 0%, #113145 131.25%)',
            }}
          />
        </Grid>
      </Grid>
    </form>
  );
};

// const AuthSSO = ({ redirectUrl = 'login' }: { redirectUrl?: string }) => {
//   const googleHandler = async () => {
//     window.open(
//       `${REACT_APP_API_URL}/api/v1/auth/google?redirectUrl=${redirectUrl}`,
//       '_self'
//     );
//   };

//   const outlookHandler = async () => {
//     window.open(
//       `${REACT_APP_API_URL}/api/v1/auth/microsoft?redirectUrl=${redirectUrl}`,
//       '_self'
//     );
//   };

//   const linkedinHandler = async () => {
//     window.open(
//       `${REACT_APP_API_URL}/api/v1/auth/linkedin?redirectUrl=${redirectUrl}`,
//       '_self'
//     );
//   };

//   return (
//     <Grid container columnSpacing={1} rowSpacing={3}>
//       <Grid item xs={12} lg={4}>
//         <Button
//           sx={{
//             height: '44px',
//             background: 'white',
//             color: '#162738',
//             fontFamily: 'Raleway',
//             fontWeight: '600',
//             fontSize: '16px',
//             lineHeight: '24px',
//             '&:hover': {
//               backgroundColor: lighten(0.3, '#9e9e9e'),
//               borderColor: lighten(0.3, '#9e9e9e'),
//             },
//             // position: 'relative',
//             // '& .MuiButton-startIcon': {
//             //   position: 'absolute',
//             //   left: '17px'
//             // }
//           }}
//           label="Google"
//           startIcon={<img src={Google} alt="Google" />}
//           onClick={googleHandler}
//         />
//       </Grid>

//       <Grid item xs={12} lg={4}>
//         <Button
//           sx={{
//             height: '44px',
//             background: 'white',
//             color: '#162738',
//             fontFamily: 'Raleway',
//             fontWeight: '600',
//             fontSize: '16px',
//             lineHeight: '24px',
//             '&:hover': {
//               backgroundColor: lighten(0.3, '#9e9e9e'),
//               borderColor: lighten(0.3, '#9e9e9e'),
//             },
//             // position: 'relative',
//             // '& .MuiButton-startIcon': {
//             //   position: 'absolute',
//             //   left: '17px'
//             // }
//           }}
//           label="Linkedin"
//           startIcon={<img src={Linkedin} alt="Linkedin" />}
//           onClick={linkedinHandler}
//         />
//       </Grid>
//       <Grid item xs={12} lg={4}>
//         <Button

//           sx={{
//             height: '44px',
//             background: 'white',
//             color: '#162738',
//             fontFamily: 'Raleway',
//             fontWeight: '600',
//             fontSize: '16px',
//             lineHeight: '24px',
//             '&:hover': {
//               backgroundColor: lighten(0.3, '#9e9e9e'),
//               borderColor: lighten(0.3, '#9e9e9e'),
//             },
//             // position: 'relative',
//             // '& .MuiButton-startIcon': {
//             //   position: 'absolute',
//             //   left: '17px'
//             // }
//           }}
//           startIcon={<img src={Microsoft} alt="Microsoft" />}
//           onClick={outlookHandler}
//           label="Microsoft"
//         />
//       </Grid>
//     </Grid>
//   );
// };

export default AuthSSO;
