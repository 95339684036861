import RightAuthDescriptionContent from './RightAuthDescriptionContent';

const RightDescriptionContent = ({
  communityLoading = false,
  communityName,
}: {
  communityLoading?: boolean;
  communityName?: string;
}) => {
  return (
    <RightAuthDescriptionContent
      communityLoading={communityLoading}
      communityName={communityName}
    />
  );
};

export default RightDescriptionContent;
